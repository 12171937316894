import React from "react";
import Popup from "./Popup";
import s from "./styles/Card.module.css";

export default function Card(props) {
  return (
    <div className={`card + ${s.container}`}>
      <img src={props.banner} alt="card" class={s.image} />
      <div class={s.overlay}>

        <div class={s.hover}>
          <div class={s.text}>{props.name}</div>
          <div className={s.espacio}></div>
          <div className={props.soldout === true ? s.off : ""}>
            <a href={props.comprar}>
              <button
                class={
                  props.proximamente == true
                    ? s.off
                    : `btn btn-danger + ${s.btn}`
                }
              >
                Comprá tus entradas
              </button>
            </a>
          </div>
          <div className={props.soldout === true ? s.on : s.off}>
            <h2>ENTRADAS AGOTADAS!</h2>
          </div>
        </div>
      </div>
      {props.status == "Geba/niceto" ? <Popup></Popup> : null}
    </div>
  );
}
