import React from "react";
import instagram from "../img/logos/instagram.png";
import spotify from "../img/logos/SPOTIFY.png";
import youtube from "../img/logos/yt.png";
import logo from "../img/logos/classic.png";
import facebook from "../img/logos/facebook.png";
import tiktok from "../img/logos/tik tok.png";
import twitter from "../img/logos/twitter-logo.png";
import s from "./styles/StickyFooter.module.css";
import spotifyLogo from "../img/logos/Spotify_Logo_RGB_Green.png";

export default function StickyFooter() {
  const spotifyUrl = "https://open.spotify.com/playlist/5HKTENZIaJpHuSP1OTuf5m?si=6dcc4cd5703543a9&nd=1"
  return (
    <div className={s.father}>
      <div className={s.root}>
        <div className={s.footer}>
          {/* <img
            width="25px"
            className={s.logo}
            src={instagram}
            alt="instagram"
          /> */}
          {/* <img width="25px" className={s.logo} src={spotify} alt="spotify" />
          <img width="55px" className={s.logo} src={youtube} alt="youtube" />
          <img width="85px" className={s.logo} src={logo} alt="logo" />
          <img width="25px" className={s.logo} src={facebook} alt="facebook" />
          <img width="25px" className={s.logo} src={tiktok} alt="tiktok" />
          <img width="25px" className={s.logo} src={twitter} alt="twitter" /> */}
          <div className={s.calltoaction}>Escucha nuestra playlist:</div>
          <a href={spotifyUrl}><img className={s.spotilogo} src={spotifyLogo} alt="spotifyLogo" /></a>
        </div>
      </div>
    </div>
  );
}
