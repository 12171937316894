import React, { useState, useEffect } from "react";
import s from "./styles/Header.module.css";

const Header = ({ scroll }) => {
  const [firstTime, setFirstTime] = useState(true);

  const [estadito, setEstadito] = useState(s.fathernone);
  //SINO PODEMEOS HACERLO ASI.
  //  document.getElementById("header").style.animation= 'animation: "2s linear 1s 1 normal both running fadein"'
  useEffect(() => {
    if (scroll === true) {
      setTimeout(() => {
        setEstadito(s.father);
      }, 2000);
      setEstadito(s.fatherin);
      setFirstTime(false);
    }
    if (scroll === false && firstTime !== true) {
      setTimeout(() => {
        setEstadito(s.fathernone);
      }, 2000);
      setEstadito(s.fatherout);
    }
  }, [scroll]);

  return (
    <div className={estadito}>
      <div className={s.root}>
        <div className={scroll === true ? s.header : s.headeroff} id="header">
          <div className={s.text}>
            VENI A BRESH CON <div className={s.itau}>ITAÚ 🌸🌸🌸25% OFF</div> EN
            TODO EL PAÍS ❤️‍🔥
          </div>
        </div>
      </div>
    </div>
  );
};
//NARANJA ITAU #EC7404
//BLANCO "WHITE"
//ROSA BRESH #e16c93
export default Header;
