import React, { useEffect, useState } from "react";
import s from "../components/styles/Home.module.css";
import Card from "./Card";
import StickyFooter from "./StickyFooter";
import Header from "./Header";
import logo from "../img/logos/classic.png";
import Popup from "./Popup";
import Footer from "./Footer";

export default function Home(props) {
  const [input, setInput] = useState("");

  const [search, setSearch] = useState(false);

  const [filtrados, setFiltrados] = useState([]);

  const busqueda = (e) => {
    setInput(e.target.value);
  };

  const filtrar = (array) => {
    let texto = input.toLowerCase();
    let arrayfiltrado = array.filter((el) =>
      el.tagsdebusqueda.toLowerCase().includes(texto)
    );
    setSearch(true);
    setFiltrados(arrayfiltrado);
  };

  const [nacional, setNacional] = useState(false);

  const [primeroNacional, setPrimeroNacional] = useState(99999);

  const [gebaNiceto, setGebaNiceto] = useState(99999);

  useEffect(() => {
    setPrimeroNacional(
      props.arrayv.findIndex((el) => el.lugar === "Nacionales")
    );
  }, [props.arrayv]);

  var screenWidth = window.screen.availWidth;

  return (
    <div className={s.fondo}>
      <div className={`sticky-top`}>
        <nav className={s.nav}>
          <img className={s.logo} src={logo} alt=""></img>
          <input
            class="form-control mr-sm-2 w-50 p-3"
            placeholder="Buscar entradas"
            value={input}
            onChange={(e) => {
              busqueda(e);
              filtrar(props.arrayv);
            }}
          ></input>
          <a href="https://heylink.me/bresh/" className={s.finnav}>
            {screenWidth > 500 ? "Busca tus fotos!" : "BUSCA TUS FOTOS!"}
          </a>
        </nav>
        <Header scroll={props.scroll} />
      </div>
      <div>
        <div className={search === true ? s.on : s.off}>
          {filtrados.map((el) => (
            <Card
              banner={el.imagen}
              comprar={el.linkdecompra}
              name={el.nombre}
            />
          ))}
        </div>
        <div className={search === false ? s.on : s.off}>
          {props.arrayv.length > 0 ? (
            props.arrayv.map((el, index) => (
              <div id={index === primeroNacional ? "primera" : "ns"}>
                <Card
                  banner={el.imagen}
                  comprar={el.linkdecompra}
                  name={el.nombre}
                  status={el.lugar}
                />
              </div>
            ))
          ) : (
            <div
              className={s.container}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
              }}
            >
              <h1 style={{ fontWeight: "600", fontSize: 50 }}>
                🔥💖Cargando..💖🔥
              </h1>
            </div>
          )}
        </div>
      </div>
      <div className={filtrados.length == 0 ? s.vacio : s.off}></div>
      <StickyFooter></StickyFooter>
      <Footer></Footer>
    </div>
  );
}
