import "./App.css";
import Home from "./components/Home.js";
import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios from "axios";

function App() {
  const [all, setAll] = useState([]);

  useEffect(() => {
    axios
      .get("https://fiesta-bresh.herokuapp.com/obtenertickets")
      .then((res) => setAll(res.data))
      .catch(() => console.log("Ocurrio un error"));
  }, []);

  const [scroll, setScroll] = useState(false);

  const [currentScroll, setCurrentScroll] = useState(0);

  function isInViewport() {
    let elem = document.getElementById("primera");
    var distance = elem.getBoundingClientRect();
    window.innerWidth > 500
      ? setScroll(
          distance.top <
            (window.innerHeight * 0.2 ||
              document.documentElement.clientHeight * 0.2) ||
            distance.bottom < 0
        )
      : setScroll(
          distance.top <
            (window.innerHeight * 0.2 ||
              document.documentElement.clientHeight) || distance.bottom < 0
        );
  }

  const order = ["Internacional", "Geba/niceto", "Nacionales"];

  const bannerSorter = all
    .sort((a, b) => new Date(b.fecha).getTime() - new Date(a.fecha).getTime())
    .reverse()
    .sort((x, y) => order.indexOf(x.lugar) - order.indexOf(y.lugar));

  return (
    <BrowserRouter>
      <div className="App" onScroll={isInViewport}>
        <Routes>
          <Route
            path="/"
            element={<Home arrayv={bannerSorter} scroll={scroll} />}
          ></Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
