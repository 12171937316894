import React from 'react'
import s from "./styles/Footer.module.css"
import instagram from "../img/logos/instagram.png";
import spotify from "../img/logos/SPOTIFY.png";
import youtube from "../img/logos/yt.png";
import logo from "../img/logos/classic.png";
import facebook from "../img/logos/facebook.png";
import tiktok from "../img/logos/tik tok.png";
import twitter from "../img/logos/twitter-logo.png";



const Footer = () => {
    return (
        <div className={s.all}>
            <div className={s.text}>
                Términos y condiciones  <a className={s.link} href="https://beneficios.iupp.com.ar/beneficios/32/fiestas-bresh-">ITAÚ</a></div>
            <div className={s.container}>
                <div>
                    <div className={s.img}>
                        <div className={s.terms}><a className={s.terms} href="https://www.breshtickets.com/static/terminos-condiciones">🌸 Términos y condiciones 🌸</a></div>
                        {/* <img width="25px" className={s.logo} src={spotify} alt="spotify" /> */}
                        {/* <img width="55px" className={s.logo} src={youtube} alt="youtube" /> */}
                        {/* <a href="https://business.facebook.com/BRESHING"><img width="45px" className={s.logo} src={facebook} alt="facebook" /> </a> */}
                        <a href=""><img width="120px" className={s.logo} src={logo} alt="logo" /> </a>
                        {/* <a href="https://www.tiktok.com/@fiestabresh"><img width="45px" className={s.logo} src={tiktok} alt="tiktok" /> </a> */}
                        {/* <a href="https://twitter.com/fiestabresh?lang=en"><img width="45px" className={s.logo} src={twitter} alt="twitter" /> </a> */}
                        <div className={s.terms}><a className={s.terms} href="https://bresh.zendesk.com/hc/es/requests/new">🌸 Contacto y soporte 🌸</a></div>
                    </div>
                </div >
            </div >
            <div className={s.copyright}><hr />Fiesta BRESH 2022 ⓒ Marca registrada. Todos los derechos reservados. </div>
        </div >
    )
}

export default Footer